.MultiSelect {
  mc-popover::part(container) {
    overflow-x: hidden;
  }

  &__trigger {
    display: flex;
    box-sizing: border-box;
    padding: 8px 12px;
    align-items: center;
    gap: 8px;
    justify-content: space-between;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid #cfcfcf;
    background: #fff;
    cursor: pointer;

    &-icon {
      margin-left: auto;
    }

    > span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__empty {
    padding: 4px 12px;
  }

  &__list {
    padding: 0;
    box-sizing: border-box;

    &-item {
      display: flex;
      flex-direction: row;
      cursor: pointer;
      align-items: center;
      padding: 6px 12px;

      &-icon {
        width: 18px;
        margin-right: 8px;
      }

      &:hover {
        background-color: rgb(234, 236, 240);
      }

      &--disabled {
        &:hover {
          background-color: transparent;
          cursor: not-allowed;
        }
      }
    }
  }

  &__label {
    font-size: 1.6rem;
    margin-bottom: 4px;
    color: #141414;
    text-align: start;
  }

  &__search,
  &__toggle {
    padding: 8px 12px 0 12px;
    box-sizing: border-box;
  }

  &__toggle {
    &-button {
      padding-left: 0 !important;
    }
  }

  &--disabled {
    .MultiSelect__trigger {
      opacity: 0.6;
      cursor: no-drop;
    }
    .MultiSelect__label {
      color: #626161;
      cursor: no-drop;
    }
  }

  &--medium {
    .MultiSelect__trigger {
      height: 38px;
      font-size: 16px;
    }
    .MultiSelect__list-item {
      padding: 4px 12px;
      font-size: 16px;
    }
    mc-checkbox::part(label),
    .MultiSelect__toggle-button,
    .MultiSelect__empty {
      font-size: 16px;
    }
  }

  &--small {
    .MultiSelect__trigger {
      height: 32px;
      font-size: 14px;
    }
    .MultiSelect__list-item {
      padding: 4px 12px;
      font-size: 14px;
    }
    mc-checkbox::part(label),
    .MultiSelect__toggle-button,
    .MultiSelect__empty {
      font-size: 14px;
    }
  }
}
