.IMOInputField {
  position: relative;

  &__addon {
    position: absolute;
    top: 2px;
    left: 35px;
    cursor: pointer;

    &__icon {
      &:hover {
        color: #8a8a90;
      }
    }
  }
}
