.SideNav {
  list-style: none;
  width: 64px;
  box-sizing: border-box;
  border-right: 1px solid #dbdbdb;
  background-color: #fff;
  height: 100%;
  position: sticky;
  top: 64px;
  padding: 0;
  margin: 0;
  z-index: 3;

  &__item {
    display: inline-flex;
    flex-direction: column;
    color: #353535;
    text-align: center;
    flex-shrink: 0;
    align-self: stretch;
    justify-content: center;
    height: 64px;
    width: 62px;
    cursor: pointer;
    border-right: 2px solid transparent;
    opacity: 1;
    transition: 0.1s opacity ease-in-out;
    position: relative;

    &--selected,
    &:hover {
      border-right-color: #0a6e82;
      opacity: 1;
    }

    &:hover {
      background-color: #f7f7f7;
    }

    &:hover {
      opacity: 1;
    }

    &--disabled {
      cursor: not-allowed;
      opacity: 0.3;
      &:hover {
        border-right-color: transparent;
        opacity: 0.3;
        background-color: transparent;
      }
    }

    &-text {
      padding-left: 4px;
      font-family: Maersk Text;
      font-size: 12px;
      font-style: normal;
      overflow: hidden;
      font-weight: 400;
      line-height: 16px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &-icon {
      display: flex;
      justify-content: center;
      margin-bottom: 2px;
    }
  }

  &__with-separator {
    border-top: 1px solid #dbdbdb;
    margin: 0 8px;
    position: absolute;
    width: 48px;
    top: 0;
  }

  &__children-wrapper {
    position: absolute;
    top: 0;
    left: calc(100% + 4px);
    background: white;
    border-radius: 4px;
    border: 1px solid #dbdbdb;
    box-shadow: 0 4px 40px 0 rgba(0, 0, 0, 0.25);
    width: max-content;

    &__item {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 12px;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background-color: #f7f7f7;
      }
    }
  }
}
