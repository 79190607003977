.EditOccValuesDrawer {
  &__form {
    display: flex;
    gap: 1rem;
  }
  &__field {
    margin: 12px 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
    & > div {
      color: red;
    }
  }
  &__buttons {
    display: flex;
    gap: 12px;
    margin-top: 32px;
  }
}
