.LinkButton {
  display: inline-flex;
  color: $color-primary-orange;
  align-items: center;
  gap: 8px;
  text-decoration: underline;
  padding: 4px;
  border: 3px solid transparent;
  border-radius: 4px;
  background-color: transparent;
  cursor: pointer;

  &--disabled {
    opacity: 60%;
    pointer-events: none;

    mc-icon::part(icon) {
      opacity: 70%;
    }
  }

  mc-icon::part(icon) {
    fill: $color-primary-orange;
  }

  &:hover {
    color: $color-primary-orange-darkest;

    mc-icon::part(icon) {
      fill: $color-primary-orange-darkest;
    }
  }

  &:focus {
    outline: 3px solid $color-primary-orange-brightest;
  }
}
