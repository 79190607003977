.Property {
  display: flex;
  flex-direction: column;
  font-family: 'Maersk Text';

  &__label {
    font-size: 12px;
    line-height: 16px;

    font-weight: 500;
    color: #63636b;

    &--tooltip {
      cursor: pointer;
    }
  }

  &__value {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
  }
}
