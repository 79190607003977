.AppLayout {
  display: flex;
  flex-direction: column;
  height: 100vh;

  &__wrapper {
    display: flex;
    height: calc(100vh - 64px);
  }

  &__content {
    overflow: auto;
    width: 100%;
  }
}
