.Topbar {
  background-color: $color-white;
  border-bottom: 1px solid $color-border-neutral-weak;
  height: 64px;
  box-sizing: border-box;
  display: flex;

  &__icon-wrapper {
    border-right: 1px solid $color-border-neutral-weak;
    width: 64px;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__icon {
    transform: scale(1.5);
  }

  &__content {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__title {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: $color-text-neutral;
    padding: 20px 24px;
  }

  &__addons {
    height: 100%;
    display: flex;
    align-items: center;
  }

  &__app-name {
    font-size: 16px;
    color: $color-text-neutral-weakest;
    padding: 20px 24px;
  }

  &__user-dropdown {
    width: 64px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid $color-border-neutral-weak;
    color: $color-text-neutral-weakest;
    box-sizing: border-box;
  }
}
