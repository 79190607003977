.Avatar {
  display: inline-flex;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
}
