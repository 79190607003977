.Drawer {
  position: fixed;
  top: 64px;
  right: -616px;
  width: 616px;
  height: calc(100vh - 64px);
  background-color: #fff;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.25);
  z-index: 11;
  transition: right 0.2s ease-in-out;

  &__wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }

  &__content {
    padding: 24px;
    overflow-y: auto;
    align-self: stretch;
    height: 100%;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    background: $colour-light-grey;
    padding: 24px;
    gap: 12px;
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    border-bottom: solid 1px $colour-light-grey-1;

    h3 {
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      margin: 0;
    }
  }

  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  &--open {
    right: 0;
  }
}
