.UserDropdown {
  mc-popover {
    &::part(content),
    &::part(container) {
      max-width: max-content;
    }
  }
  mc-button {
    width: 44px;
    height: 40px;

    &::part(button) {
      width: 44px;
    }
  }

  &--open {
    mc-button {
      &::part(button) {
        background-color: #cfcfcf;
      }
    }
  }

  &__window {
    min-width: 300px;
    font-size: 14px;
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    border-bottom: 1px solid $color-border-neutral-weak;
    padding: 8px;
    width: 100%;
  }

  &__avatar {
    width: 40px;
  }

  &__user-details {
    margin-left: 8px;
    display: flex;
    flex-direction: column;
    span {
      &:first-of-type {
        color: #141414;
      }
      &:last-of-type {
        color: #878787;
      }
    }
  }

  &__user-actions {
    padding: 8px 0;
  }

  &__action {
    display: flex;
    align-items: center;
    padding: 10px 12px;
    cursor: pointer;
    transition: 0.1s background-color ease-in;

    &:hover {
      background-color: rgba(20, 20, 20, 0.08);
    }

    &-icon {
      margin-right: 10px;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    padding: 10px 8px;
    border-top: 1px solid $color-border-neutral-weak;
    font-size: 10px;
    line-height: 16px;
    color: #878787;
  }
}
