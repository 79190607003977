.CapacityIdContainer {
  padding: 12px 24px;

  &__dropdown-wrapper {
    background: #e6ebf2;
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 8px 24px;
    margin: -12px -24px;

    &__content {
      width: 20rem;
    }
  }

  &__heading {
    font-weight: 400;
    margin: 32px 0 16px;
  }

  h2 {
    font-weight: 400;
  }
}
