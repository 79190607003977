.CapacityTab {
  width: max-content;

  &__default-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__selected-rows-banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    margin-bottom: 1rem;
    padding: 0.75rem 1rem;
    background-color: #fae5da;
    border: 1px solid #ff7a59;
  }
}
