@import 'src/styles/tokens/colors.scss';

.IMODrawerContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 112px);

  &__title {
    display: flex;
    align-items: center;
    margin: -24px;
    padding: 24px;
    margin-bottom: 24px;
    border-bottom: solid 1px $colour-light-grey-1;

    &__back {
      padding-right: 8px;
      height: 20px;

      &:hover {
        color: #8a8a90;
        cursor: pointer;
      }
    }

    h3 {
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      margin: 0;
    }
  }
  &__textareawrapper {
    align-self: stretch;
    height: 100%;
  }
}

.IMODrawerFooter {
  display: flex;
  justify-content: flex-end;
  background: $colour-light-grey;
  margin: 24px -24px -24px;
  padding: 24px;
  gap: 12px;
}
