.TerminalCarriersRenderer {
  display: flex;
  flex-direction: row;

  &__cell {
    padding: 10px 14px;
    border-bottom: 1px solid rgb(128, 128, 128, 0.3);
    border-collapse: collapse;
    box-sizing: border-box;
  }

  &__logo {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &__row {
    color: $color-text-neutral-weakest;
    font-size: 14px;
  }
}
