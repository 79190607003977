$table: '.Table';

#{$table} {
  &__filter {
    &-container {
      margin-bottom: 1rem;
      display: flex;
      justify-content: space-between;

      > div {
        display: flex;
      }
    }

    &-toggle {
      margin-right: 0.5rem;
    }

    &-count {
      background: $color-primary-blue-600;
      color: $color-white;
      font-size: 1.1rem;
      padding: 0.1rem 0.4rem;
      align-self: center;
      margin: auto 0.5rem;
      border-radius: 0.3rem;
    }

    &-label {
      font-size: 1rem;
      border-left: 1px solid $color-dark-grey;
      padding-left: 0.5rem;
      margin: auto 0;
    }

    &-clear {
      justify-content: space-between;

      > button {
        font-size: 1.2rem;
      }
    }
  }

  &__table {
    display: table;
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    font-size: 14px;
    text-align: left;
    border-spacing: 0;
    margin-bottom: 32px;

    &--compact {
      & > tbody {
        display: block;
        height: 50vh;
        overflow-y: scroll;
        overflow-x: hidden;
      }

      & > thead,
      tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;
      }

      & > tbody::-webkit-scrollbar {
        width: 0.75rem;
        border-radius: 10px;
      }

      & > thead {
        width: calc(100% - 0.75rem);
      }
    }
  }

  &__td,
  &__th {
    padding: 16px;
  }

  &__th,
  &__td {
    padding: 16px;
    border-right: 1px hidden #dbdbdb;
    border-top: 1px solid #dbdbdb;
    border-bottom: 1px solid #dbdbdb;

    span {
      line-height: 20px;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    &:first-of-type {
      border-left: 1px solid #dbdbdb;
    }

    &:last-of-type {
      border-right: 1px solid #dbdbdb;
    }
  }

  &__td {
    &--right {
      text-align: right;
    }

    &--center {
      text-align: center;
    }

    &--left {
      text-align: left;
    }

    &--compact {
      & div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
      }
    }

    &.node {
      cursor: pointer;

      & svg {
        width: 0.6rem;
        height: 0.7rem;
        margin-left: 0.7rem;
        display: inline-block;
        vertical-align: middle;
        transition: all 0.5s ease;
        transform-origin: center center;
      }

      &.is-open {
        & svg {
          transform: rotate(90deg);
        }
      }
    }
  }

  &__sort-icon {
    fill: $color-grey-600;
  }

  &__th {
    background-color: $color-grey-100;

    &--sortable {
      cursor: pointer;

      &:hover {
        background-color: #f1f1f1;

        .Table__sort-icon {
          fill: $color-black;
        }
      }
    }

    &--filter {
      padding: 1rem;
    }
  }

  &__tr {
    &:hover {
      .Table__td {
        background: linear-gradient(0deg, rgba(181, 224, 245, 0.3) 0%, rgba(181, 224, 245, 0.3) 100%), #fff;
      }
    }

    &--highlight {
      background: linear-gradient(0deg, rgba(181, 224, 245, 0.3) 0%, rgba(181, 224, 245, 0.3) 100%);
    }

    &--disabled {
      opacity: 0.5;

      &:hover {
        .Table__td {
          background: none;
        }
      }
    }

    &--selected {
      &-before {
        .Table__td {
          border-bottom: 1px solid #42b0d5;
        }
      }

      .Table__td {
        background: linear-gradient(0deg, rgba(181, 224, 245, 0.15) 0%, rgba(181, 224, 245, 0.15) 40%), #fff;
        border-right: 1px dashed #42b0d5;
        border-top: 1px solid #42b0d5;
        border-bottom: 1px solid #42b0d5;

        &:first-of-type {
          border-left: 1px solid #42b0d5;
        }

        &:last-of-type {
          border-right: 1px solid #42b0d5;
        }
      }
    }
  }

  &__sort-icon-wrapper {
    width: 40px;
    display: inline-flex;
    justify-content: right;
  }
}
