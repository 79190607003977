.RulesContainer {
  padding: 12px 24px;

  &__cards {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }

  &__card {
    width: 400px;

    &-properties {
      margin-top: 8px;
      display: flex;
      flex-direction: row;
      gap: 24px;
    }
  }

  &__admin-view {
    display: flex;
    flex-direction: column;
  }
}
