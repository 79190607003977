.EditDefaultValuesDrawer {
  height: 100%;

  &__form {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    height: 100%;
  }

  &__field {
    margin: 12px 0;
    display: flex;
    flex-direction: column;
    gap: 8px;

    & > div {
      color: red;
    }
  }

  &__content {
    height: 100%;
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    background: $colour-light-grey;
    padding: 24px;
    gap: 12px;
    margin: 32px -24px -24px;
  }
}
