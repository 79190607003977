$grey: #BBC3CF;
$grey-text: #8A8A90;

.ChangelogSteps {
  height: 100%;
  overflow: auto;
  margin: -24px;

  &__wrapper {
    min-height: calc(100% - 32px);
    border-left: 1px solid $grey;
    margin-left: 29px;
    margin-top: 32px;
  }
}

.ChangelogStep {
  padding: 0 24px;
  position: relative;

  &__text-wrapper {
    translate: 0 -8px;
    padding-bottom: 24px;
  }

  &__field {
    font-weight: 600;
  }

  &__value {
    font-style: italic;
  }


  &__author-date {
    color: $grey-text;
    font-size: 14px;
  }

  &:before {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: $grey;
    position: absolute;
    top: 0;
    left: -5px;
  }
}

.EmptyChangelog {
  display: flex;
  gap: 8px;
  align-items: center;
  margin: 0 auto;
}
