.UsersContainer {
  padding: 12px 24px;

  &__dropdown-wrapper {
    background: #e6ebf2;
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 8px 24px;
    margin: -12px -24px;

    &__content {
      width: 20rem;
    }
  }

  &__heading {
    font-weight: 400;
    margin: 32px 0 16px;
  }

  &__table-wrapper {
    width: max-content;
    min-width: 800px;
  }

  &__title {
    font-weight: 400;
  }

  &__search-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 24px 0;
    gap: 16px;

    &__input {
      width: 20rem;
    }
  }
}
